* {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    background: 0 0;
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
    outline: 0;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="text"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 5px;
    background: 0 0;
    font-size: 15px;
}

input[type="button"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

input[type="number"] {
    -moz-appearance: textfield;
}

ol,
ul {
    list-style: none;
}

blockquote {
    quotes: none;
}

blockquote:after,
blockquote:before {
    content: "";
    content: none;
}

del {
    text-decoration: line-through;
}

address,
article,
aside,
footer,
header,
nav,
section {
    display: block;
}

body {
    font-family: "gordita_regular";
    font-size: 17px;
    overflow-x: hidden;
    min-width: 320px;
}

body::-webkit-scrollbar {
    display: none;
}

input,
select,
textarea {
    font-family: "gordita_regular";
}

html {
    overflow: scroll !important;
    scroll-behavior: smooth !important;
}

html.modal-enabled {
    overflow: hidden !important;
}

a {
    color: #444;
}

.submit:hover,
a:hover {
    -moz-opacity: 0.85;
    -khtml-opacity: 0.85;
    opacity: 0.85;
}

.thumb {
    display: block;
}

.thumb img {
    display: block;
    width: 100%;
}

p {
    line-height: 1.6em;
    font-size: 16px;
    color: #868686;
    font-family: gordita_regular;
}

.hidden {
    display: none;
}

br.clear {
    clear: both;
    line-height: 0;
}

.bold {
    font-family: gordita_medium;
}

.e-t-h {
    margin-bottom: 25px;
}

.e-t-h2 {
    font-size: 37px;
    color: #444;
    font-family: gordita_medium;
    letter-spacing: 0.02rem;
}

.b-regular {
    font-family: gordita_bold;
}

.b-medium {
    font-family: gordita_medium;
}

.b-semi {
    font-family: gordita_regular;
}

.white {
    color: #fff;
}

.black {
    color: #000;
}

h1,
h2,
input {
    font-family: gordita_bold;
    color: #000;
    font-weight: 300;
}

input::-webkit-input-placeholder {
    color: #9b9a9a;
}

input:-moz-placeholder {
    color: #9b9a9a;
}

@font-face {
    font-display: auto;
    font-family: gordita_regular;
    src: url(../fonts/gordita/GorditaRegular.woff) format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-display: auto;
    font-family: gordita_medium;
    src: url(../fonts//gordita/GorditaMedium.woff) format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: gordita_bold;
    src: url(../fonts//gordita/GorditaBold.woff) format("woff");
    font-weight: 400;
    font-style: normal;
}

.wrapper {
    width: 75%;
    margin: 0 auto;
}

.inner-wrapper {
    width: 70%;
    margin: 0 auto;
}

@media all and (max-width: 1280px) {
    .inner-wrapper {
        width: 80%;
    }
}
@media all and (max-width: 1080px) {
    .wrapper {
        width: 80%;
    }
}

@media all and (max-width: 640px) {
    .inner-wrapper {
        width: 85%;
    }

    .wrapper {
        width: 90%;
    }
}

@media all and (max-width: 480px) {
    .inner-wrapper {
        width: 90%;
    }
    p {
        font-size: 14px;
    }
}

/* PAGINATION */

.pagination {
    display: flex;
    justify-content: center;
    cursor: pointer;
    margin-top: 30px;
    flex-wrap: wrap;
}

.pagination a {
    border: 1px solid rgb(15, 167, 111);
    width: 40px;
    height: 40px;
    display: flex;
    font-family: gordita_medium;
    font-size: 15px;
    padding: 10px;
    border-radius: 50%;
    color: rgb(15, 167, 111);
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;
}

@media all and (max-width: 480px) {
    .pagination a {
        padding: 4px 12px;
    }
}

.pagination li {
    margin-right: 15px;
}

.pagination li.break a {
    display: inline-block;
}

.previous .pagination__link {
    background-color: unset;
    color: rgb(15, 167, 111);
    font-family: "gordita_medium";
}

.next .pagination__link {
    background-color: unset;
    color: rgb(15, 167, 111);
    font-family: "gordita_medium";
}

.pagination__link {
    font-weight: bold;
}

.pagination__link--active a {
    color: #fff;
    background: rgb(15, 167, 111);
}

.pagination__link--disabled a {
    cursor: not-allowed;
    display: none;
}

.active_link_classname {
    color: #fff;
    background: rgb(15, 167, 111);
}

/* PAGINATION ENDS*/
